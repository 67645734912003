<template>
  <div class="wrapper">
    <Section>
      <HeadingBlock :data="noticiasContent" />
    </Section>
    <Section>
      <IndexBlock
        :indexOptions="indexOptions"
        :data="noticiasData"
        :badgeType="badgeType"
      />
    </Section>
    <Section v-if="seo">
      <SEO
        :seo="seo.general"
        :banner="seo.general.seoBannerImage"
        :link="seo.general.seoBannerLink"
      />
    </Section>
    <CTA />
  </div>
</template>

<script>
import CTA from "@/components/CTA/CTA.vue";
import IndexBlock from "@/components/Index/IndexBlock.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "News",
  metaInfo() {
    return manageSEO(this.seo, this.seoHomepage, false, this.dealerName);
  },
  components: {
    CTA,
    IndexBlock,
    Section,
    SEO,
    HeadingBlock,
  },
  data() {
    return {
      indexOptions: {
        type: "index",
        link: "NoticiasSingle",
        button: true,
      },
      badgeType: "Notícias",
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  computed: {
    noticiasData() {
      return this.$store.getters.getAllNews;
    },
    noticiasContent() {
      return this.$store.state.noticiasContent;
    },
    seo() {
      return this.$store.state.noticiasSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo.general;
    },
  },
};
</script>
